import React from 'react'
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  CardMedia,
} from '@mui/material'

import { Mail } from '@mui/icons-material'

import { formatDateWithoutTime } from 'src/new/components/formatDate'

import bannerkjmis from 'src/new/assets/images/bannerkjmis.jpeg'

import simjangka from 'src/new/assets/images/produk/simjangka.jpeg'
import ppob from 'src/new/assets/images/produk/ppob.png'
import bni from 'src/new/assets/images/produk/bni.jpeg'
import tablebni from 'src/new/assets/images/produk/tablebni.png'
import { useTheme, useMediaQuery } from '@material-ui/core'

import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

export function Produk() {
  const now = new Date()

  const theme = useTheme()
  const mobileAndTablet = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const largeScreen = useMediaQuery(theme.breakpoints.only('xl'))

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein }
  }

  const rows = [
    createData('JENIS PRODUK', 'Asuransi Kecelakaan'),
    createData(
      'SALURAN PEMASARAN',
      'Digital (Mobile Apps BNI)/ distribusi Bancassurance'
    ),
    createData('TARGET', 'Individu'),
    createData('USIA MASUK', '18 s.d. 65 Tahun'),
    createData(
      'MANFAAT ASURANSI',
      `Santunan Meninggal Dunia karena Kecelakaan Jika Peserta
    meninggal dunia akibat kecelakaan dan Bukti Kepesertaan masih
    berlaku, BNI Life Insurance akan membayarkan santunan sebesar
    100%. Nilai penggantian atas Meninggal Dunia karena kecelakaan
    sesuai nilai santunan yang jadi hak peserta dan selanjutnya
    kepersertaan asuransi berakhir`
    ),
    createData(
      'SANTUNAN ASURANSI DAN PREMI',
      <CardMedia component='img' image={tablebni} alt='ppob' />
    ),
  ]

  return (
    <Container sx={{ py: 10 }}>
      <CardMedia component='img' image={bannerkjmis} alt='bannerkjmis' />
      <Box
        sx={{
          width: 'auto',
          height: 40,
          backgroundColor: '#dd4949',
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography color='white'>{formatDateWithoutTime(now)}</Typography>
          <Link
            to='mailto:cs@kjmis.id'
            underline='hover'
            sx={{ paddingLeft: 1 }}
          >
            <Button variant='text' sx={{ color: 'white' }} startIcon={<Mail />}>
              cs@kjmis.id
            </Button>
          </Link>
        </Grid>
      </Box>
      <Grid>
        <Typography pt={5} variant='h2'>
          Produk KJMIS
        </Typography>
        <Typography pt={3} variant='h5'>
          Simpanan
        </Typography>
        <Typography pl={2} pt={1} variant='body2'>
          &rarr; Simpanan Pokok adalah merupakan keuangan yang harus dibayarkan
          oleh setiap anggota ke koperasi saat pertama kali masuk dan tidak
          dapat diambil kembali. Simpanan ini jumlahnya sama untuk setiap
          anggota sebesar Rp. 15.000,-
          <br />
          <br />
          &rarr; Simpanan Wajib adalah sejumlah uang yang harus dibayar oleh
          anggota ke koperasi dengan jumlah minimal Rp. 10.000,- setiap
          bulannya. Anggota tidak dapat mengambil simpanan ini selama masih
          resmi menjadi anggota.
          <br />
          <br />
          &rarr; Simpanan Sukarela adalah tabungan anggota yang besarnya
          tergantung kemampuan anggota. Anggota diharapkan menumbuhkan kebiasaan
          menabung dan melakukan simpanan berjangka di KJMIS. Simpanan Sukarela
          (Tabungan Koperasi) yang ada di KJMIS merupakan hasil dari setoran
          serta penarikan yang dilakukan oleh anggota yang dicatat dalam sebuah
          buku tabungan KJMIS. Penyetoran atau penarikan dengan buku tabungan
          dapat dilakukan oleh anggotanya setiap saat melalui sistem aplikasi
          gateway payment KJMIS.
          <br />
          <br />
          &rarr; Simpanan Berjangka adalah simpanan yang diperlakukan sama
          dengan deposito yang akan dimanfaatkan secara produktif dalam bentuk
          pinjaman berdasarkan prinsip bagi hasil .
        </Typography>
        <Typography pt={4} variant='h5'>
          Pembiayaan
        </Typography>
        <Typography pl={2} pt={1} variant='body2'>
          Kredit Pembiayaan memang masih menjadi pilihan disaat sedang
          kekurangan dana. KJMIS memberikan solusinya dengan program pembiayaan
          dengan persyaratan mudah, proses cepat, jasa pinjaman yang bersaing
          dan bebas biaya pinalti untuk pelunasan dipercepat. Pembiayaan KJMIS
          bersifat produktif, menciptakan kegiatan usaha dalam rangka
          menghasilkan produk barang atau jasa yang bermanfaat sehingga
          memberikan keuntungan dari kegiatan tersebut.
          <br />
          <br />
          Beberapa macam jenis pembiayaan di KJMIS yang dapat dimanfaatkan
          anggota-anggotanya antara lain:
          <br />
          <br />
          &rarr; KJMIS UKM, adalah pinjaman modal usaha dan investasi ,
          diberikan kepada anggota yang akan digunakan untuk modal kerja atau
          modal usaha dengan atau tanpa jaminan benda bergerak atau tidak
          bergerak.
          <br />
          <br />
          &rarr; KJMIS Pintar, adalah pinjaman untuk melanjutkan pendidikan bagi
          keluarga anggota. KJMIS memberikan anggota mendapatkan kemudahan dana
          tunai melalui pembiayaan tanpa agunan untuk biaya perguruan tinggi
          dalam negeri, tenor hingga 5 tahun.
          <br />
          <br />
          &rarr; KJMIS Express, adalah pinjaman dana talangan dari KJMIS kepada
          anggota. Pembiayaan ini memiliki keunggulan proses pencaira yang
          sangat cepat (ekspress).
        </Typography>
      </Grid>
      <Typography pt={5} variant='h5'>
        Simpanan Berjangka & PPOB
      </Typography>
      <Grid container pt={5}>
        <Grid item lg={4}>
          <CardMedia
            sx={{ width: 300, height: 150 }}
            component='img'
            image={simjangka}
            alt='simjangka'
          />
        </Grid>
        <Grid item lg={8}>
          <Typography pl={2} pt={1} variant='buttontext'>
            SIMPANAN BERJANGKA
          </Typography>
          <Typography pl={2} pt={1} variant='body2'>
            Simpanan yang diperlakukan sama dengan deposito yang akan
            dimanfaatkan secara produktif dalam bentuk pinjaman berdasarkan
            prinsip bagi hasil.
          </Typography>
        </Grid>
      </Grid>
      <Grid container pt={5}>
        <Grid item lg={10}>
          <Typography pl={2} pt={1} variant='buttontext'>
            PELAYANAN PEMBAYARAN TAGIHAN BULANAN (PPOB)
          </Typography>
          <Typography pl={2} pt={1} variant='body2'>
            Payment Point Online Bank, merupakan suatu loket layaknya bank yang
            dapat digunakan untuk membayar berbagai tagihan dalam satu tempat.
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <CardMedia
            sx={{ width: 200, height: 200 }}
            component='img'
            image={ppob}
            alt='ppob'
          />
        </Grid>
      </Grid>
      <Typography pt={5} variant='h5'>
        Produk Asuransi BNI Life
      </Typography>
      <CardMedia
        sx={{ width: 250, height: 150 }}
        component='img'
        image={bni}
        alt='ppob'
      />
      {mobileAndTablet && (
        <TableContainer sx={{ pt: 5 }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>NAMA PRODUK</StyledTableCell>
                <StyledTableCell align='left'>
                  BNI LIFE DIGI MICRO PROTECTION (KONVENSIONAL)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component='th' scope='row'>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align='left'>{row.calories}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {mediumScreen && (
        <TableContainer sx={{ pt: 5 }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>NAMA PRODUK</StyledTableCell>
                <StyledTableCell align='left'>
                  BNI LIFE DIGI MICRO PROTECTION (KONVENSIONAL)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component='th' scope='row'>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align='left'>{row.calories}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {largeScreen && (
        <>
          <Grid container pt={5}>
            <Grid item lg={3}>
              <Typography variant='h6'>NAMA PRODUK</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='h6'>
                BNI LIFE DIGI MICRO PROTECTION (KONVENSIONAL)
              </Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>JENIS PRODUK</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='subtitle1'>Asuransi Kecelakaan</Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>SALURAN PEMASARAN</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='subtitle1'>
                Digital (Mobile Apps BNI)/ distribusi Bancassurance
              </Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>TARGET</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='subtitle1'>Individu</Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>USIA MASUK</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='subtitle1'>18 s.d. 65 Tahun</Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>MANFAAT ASURANSI</Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant='subtitle1'>
                Santunan Meninggal Dunia karena Kecelakaan Jika Peserta
                meninggal dunia akibat kecelakaan dan Bukti Kepesertaan masih
                berlaku, BNI Life Insurance akan membayarkan santunan sebesar
                100%. Nilai penggantian atas Meninggal Dunia karena kecelakaan
                sesuai nilai santunan yang jadi hak peserta dan selanjutnya
                kepersertaan asuransi berakhir
              </Typography>
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item lg={3}>
              <Typography variant='h6'>SANTUNAN ASURANSI DAN PREMI</Typography>
            </Grid>
            <Grid item lg={9}>
              <CardMedia component='img' image={tablebni} alt='ppob' />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}
