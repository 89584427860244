import * as React from 'react'

import { Close } from '@mui/icons-material'

import 'src/new/assets/css/gallery.css'

import jaksel from 'src/new/assets/images/photos/jaksel.jpeg'
import jawatimur from 'src/new/assets/images/photos/jawatimur.jpeg'
import kabbatang from 'src/new/assets/images/photos/kabBatang.jpeg'
import kediri from 'src/new/assets/images/photos/kediri.jpeg'
import malang from 'src/new/assets/images/photos/malang.jpeg'
import probolinggo from 'src/new/assets/images/photos/probolinggo.jpeg'
import a1 from 'src/new/assets/images/photos/a1.jpeg'
import a2 from 'src/new/assets/images/photos/a2.jpeg'
import a3 from 'src/new/assets/images/photos/a3.jpeg'
import a4 from 'src/new/assets/images/photos/a4.jpeg'
import a5 from 'src/new/assets/images/photos/a5.jpeg'
import a6 from 'src/new/assets/images/photos/a6.jpeg'
import tulungagung from 'src/new/assets/images/photos/tulungagung.jpeg'
import tulungagung1 from 'src/new/assets/images/photos/tulungagung1.jpeg'
import salatiga from 'src/new/assets/images/photos/salatiga.jpeg'
import salatiga1 from 'src/new/assets/images/photos/salatiga1.jpeg'
import salatiga2 from 'src/new/assets/images/photos/salatiga2.jpeg'
import ponorogo from 'src/new/assets/images/photos/ponorogo.jpeg'
import ponorogo1 from 'src/new/assets/images/photos/ponorogo1.jpeg'
import ponorogo2 from 'src/new/assets/images/photos/ponorogo2.jpeg'
import ponorogo3 from 'src/new/assets/images/photos/ponorogo3.jpeg'
import ponorogo4 from 'src/new/assets/images/photos/ponorogo4.jpeg'
import makassar from 'src/new/assets/images/photos/makassar.jpeg'
import makassar1 from 'src/new/assets/images/photos/makassar1.jpeg'
import blitar from 'src/new/assets/images/photos/blitar.jpeg'
import sukoharjo from 'src/new/assets/images/photos/sukoharjo.jpeg'
import wonogiri from 'src/new/assets/images/photos/wonogiri.jpeg'
import tanahabang from 'src/new/assets/images/photos/tanahabang.jpeg'

import kampungbugis from 'src/new/assets/images/photos/kampungbugis.jpeg'
import salamanberdua from 'src/new/assets/images/photos/salamanberdua.jpeg'
import sosialisasiapp from 'src/new/assets/images/photos/sosialisasiapp.jpeg'
import sosialisasikartubni from 'src/new/assets/images/photos/sosialisasikartubni.jpeg'
import launchingewarong from 'src/new/assets/images/photos/launchingewarong.jpeg'

import KKSS from 'src/new/assets/images/KKSS.jpeg'
import rapatKKSS from 'src/new/assets/images/rapatKKSS.jpeg'
import kumpulanPengurusKKSS from 'src/new/assets/images/kumpulanPengurusKKSS.jpeg'
import HUTKKSS from 'src/new/assets/images/HUTKKSS.jpeg'
import ikajoss1 from 'src/new/assets/images/ikajoss1.jpeg'
import ikajoss2 from 'src/new/assets/images/ikajoss2.jpeg'
import ikajoss3 from 'src/new/assets/images/ikajoss3.jpeg'

export function Galeri() {
  const [model, setModel] = React.useState(false)
  const [tempImgSrc, setTempImgSrc] = React.useState('')
  const [titleImg, setTitleImg] = React.useState('')
  const [dateImg, setDateImg] = React.useState('')

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc)
    setModel(true)
  }

  const getTitle = (a) => {
    setTitleImg(a)
  }

  const getDate = (a) => {
    setDateImg(a)
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <div className={model ? 'model open' : 'model'}>
        <p>
          {titleImg}, {dateImg}
        </p>
        <img src={tempImgSrc} alt={tempImgSrc} />
        <Close onClick={() => setModel(false)} />
      </div>
      <div className='gallery'>
        {itemData.map((item, index) => {
          return (
            <div
              className='pics'
              key={index}
              onClick={() => {
                getImg(item.img)
                getTitle(item.title)
                getDate(item.date)
              }}
            >
              <img alt={item.title} src={item.img} style={{ width: '100%' }} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const itemData = [
  {
    id: 1,
    img: jaksel,
    title: 'Jakarta Selatan',
    date: '29 September 2021',
  },
  {
    id: 2,
    img: jawatimur,
    title: 'Jawa Timur',
    date: '14 Oktober 2021',
  },
  {
    id: 3,
    img: kabbatang,
    title: 'Kabupaten Batang',
    date: '16 Oktober 2021',
  },
  {
    id: 4,
    img: kediri,
    title: 'Kediri',
    date: '15 Oktober 2021',
  },
  {
    id: 5,
    img: malang,
    title: 'Malang',
    date: '14 Oktober 2021',
  },
  {
    id: 6,
    img: probolinggo,
    title: 'Probolinggo',
    date: '14 Oktober 2021',
  },
  {
    id: 7,
    img: a1,
    title: 'Jogja',
    date: '20 Oktober 2021',
  },
  {
    id: 8,
    img: a2,
    title: 'Jogja',
    date: '28 Oktober 2021',
  },
  {
    id: 9,
    img: blitar,
    title: 'Blitar',
    date: '30 Oktober 2021',
  },
  {
    id: 10,
    img: a3,
    title: 'Blitar',
    date: '30 Oktober 2021',
  },
  {
    id: 11,
    img: a4,
    title: 'Blitar',
    date: '30 Oktober 2021',
  },
  {
    id: 12,
    img: a5,
    title: 'Blitar',
    date: '30 Oktober 2021',
  },
  {
    id: 13,
    img: a6,
    title: 'Blitar',
    date: '30 Oktober 2021',
  },
  {
    id: 14,
    img: tulungagung,
    title: 'Tulungagung',
    date: '30 Oktober 2021',
  },
  {
    id: 15,
    img: tulungagung1,
    title: 'Tulungagung',
    date: '30 Oktober 2021',
  },
  {
    id: 16,
    img: salatiga,
    title: 'Salatiga',
  },
  {
    id: 17,
    img: salatiga1,
    title: 'Salatiga',
    date: '1 November 2021',
  },
  {
    id: 18,
    img: salatiga2,
    title: 'Salatiga',
    date: '1 November 2021',
  },
  {
    id: 19,
    img: ponorogo,
    title: 'Ponorogo',
    date: '1 November 2021',
  },
  {
    id: 20,
    img: ponorogo1,
    title: 'Ponorogo',
    date: '1 November 2021',
  },
  {
    id: 21,
    img: ponorogo2,
    title: 'Ponorogo',
    date: '1 November 2021',
  },
  {
    id: 22,
    img: ponorogo3,
    title: 'Ponorogo',
    date: '1 November 2021',
  },
  {
    id: 23,
    img: ponorogo4,
    title: 'Ponorogo',
    date: '1 November 2021',
  },
  {
    id: 24,
    img: makassar,
    title: 'Makassar',
    date: '10 November 2021',
  },
  {
    id: 25,
    img: makassar1,
    title: 'Makassar',
    date: '10 November 2021',
  },
  {
    id: 26,
    img: sukoharjo,
    title: 'Sukoharjo',
    date: '7 Januari 2022',
  },
  {
    id: 27,
    img: wonogiri,
    title: 'Wonogiri',
    date: '7 Januari 2022',
  },
  {
    id: 28,
    img: tanahabang,
    title: 'Tanah Abang, Jakarta',
    date: '14 Januari 2022',
  },
  {
    id: 29,
    img: kampungbugis,
    title: 'Kampung Bugis',
    date: '12 Februari 2022',
  },
  {
    id: 30,
    img: salamanberdua,
    title: '',
  },
  {
    id: 31,
    img: sosialisasiapp,
    title: 'Sosialisasi App',
  },
  {
    id: 32,
    img: sosialisasikartubni,
    title: 'Sosialisasi Kartu Atm BNI',
  },
  {
    id: 33,
    img: launchingewarong,
    title: 'Launching E-Warong',
  },
]
