import React from 'react'

import KJMISBG from 'src/new/assets/images/bannerkjmis.jpeg'
import kartu from 'src/new/assets/images/berita/kartu.jpeg'
import kopdig from 'src/new/assets/images/berita/kopdig.jpeg'
import mensospidato from 'src/new/assets/images/berita/mensospidato.jpeg'
import ramerame from 'src/new/assets/images/berita/ramerame.jpeg'
import salaman from 'src/new/assets/images/berita/salaman.jpeg'

import {
  Container,
  Typography,
  CardMedia,
  Box,
  Divider,
  Link,
} from '@mui/material'

import { useParams } from 'react-router-dom'

export function Berita() {
  const { string } = useParams()
  console.log(string)
  return (
    <Container sx={{ py: 10 }}>
      <CardMedia component='img' image={KJMISBG} alt='bannerkjmis' />
      <Divider sx={{ pt: 5, mx: 20 }} />
      {/* {contents.map((data, index) => (
        <div key={index}> */}
      {string === 'n0' || string === 'semua' ? (
        <Box pt={5}>
          <Typography variant='h2' sx={{ pb: 3 }}>
            Mensos Agus Gumiwang Luncurkan Koperasi Digital
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={kopdig}
            alt='Mensos Agus Gumiwang Luncurkan Koperasi Digital'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            Menteri Sosial RI Agus Gumiwang Kartasasmita mengungkapkan kemajuan
            teknologi informasi harus dimanfaatkan sebesar-besarnya untuk
            memperbaiki kehidupan masyarakat prasejahtera. <br />
            <br /> Salah satunya melalui koperasi berbasis digital yakni
            Koperasi Jaringan Masyarakat Indonesia Sejahtera (KJMIS) yang
            diluncurkan bersamaan dengan kegiatan Penguatan Kapasitas Pendamping
            Sosial Bantuan Sosial Pangan di Jakarta, Selasa, (12/3/2019).
            <br />
            <br /> “Pertemuan ini memiliki benang merah yaitu pertama, bagaimana
            kita terus berupaya memperbaiki kehidupan masyarakat prasejahtera.
            Kedua, memanfaatkan teknologi dan inovasi yang ada untuk
            mensejahterakan mereka,” tutur Mensos dalam sambutannya.
            <br />
            <br /> Langkah awal berjalannya KJMIS adalah adanya Nota Kesepahaman
            antara Direktur Jenderal Penanganan Fakir miskin dengan Koperasi
            Jaringan Masyarakat Indonesia Sejahtera (KJMIS) tentang Pemberdayaan
            Kelompok Usaha Bersama Dalam Rangka Penanganan Fakir Miskin.
            <br />
            <br /> KJMIS merupakan badan usaha berbentuk koperasi yang berdiri
            pada tanggal 12 Desember 2017 sesuai dengan keputusan Menteri
            Koperasi dan Usaha Kecil dan Menengah Nomor 006503 BH/ M.KUKM.2
            /XII/2017. Tujuan KJMIS adalah koperasi Primer Nasional.
            <br />
            <br /> Menteri menjelaskan KJMIS menempatkan anggotanya sebagai
            pemilik dan sebagai pelanggan. Anggota koperasi dapat berbelanja di
            KJMIS dan mendapatkan manfaat atau hak-hak sebagai anggota seperti
            potongan harga dan bonus, serta pembagian sisa hasil usaha.
            <br />
            <br /> “Kemudian misalnya KPM ingin mengembangkan diri dan ingin
            membuka usaha rumahan, maka sebagai anggota koperasi mereka memiliki
            hak meminjam modal agar bisa memulai usahanya tersebut,” terang
            Mensos.
            <br />
            <br /> Keanggotaan koperasi ini bersifat suka rela dan setiap warga
            negara Indonesia berhak angggotanya, diutamakan adalah masyarakat
            prasejahtera. Dalam pelaksanaannya, KJMIS bekerja sama dengan
            Himpunan Bank Milik Negara (Himbara), Pay Tren, Rajawali Nusantara
            Indonesia (RNI), Bulog, Dana Sejahtera, Sahara, Pilar Menara Mas,
            Pegadaian, dan PPI.
            <br />
            <br /> Untuk menjadi anggota, ada beberapa langkah yang dilakukan
            yakni mengunduh aplikasi KJMIS di playstore dan mengikuti proses
            pendaftaran secara online dengan memasukkan Nomor Induk
            Kependudukan.
            <br />
            <br /> “Saat ini salah satu inovasi yang akan dilakukan adalah untuk
            transportasi penyaluran Bantuan Pangan Non Tunai untuk penerima
            bantuan, dimana melalui transportasi online Golek, KPM tidak perlu
            pergi mengambil bantuan pangan di e-warong. KPM dapat memesan
            kebutuhan pokok untuk kemudian diantarkan ke rumah KPM,” tutur Agus.
          </Typography>
          <Link
            href='https://www.tribunnews.com/nasional/2019/03/12/mensos-agus-gumiwang-luncurkan-koperasi-digital'
            underline='none'
          >
            <Typography>Sumber</Typography>
          </Link>
        </Box>
      ) : null}
      {string === 'n1' || string === 'semua' ? (
        <Box pt={10}>
          <Typography variant='h2' sx={{ py: 3 }}>
            Mensos Luncurkan Koperasi Digital
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={mensospidato}
            alt='Mensos Luncurkan Koperasi Digital'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            (Jakarta, Pripos.id 12/3) – Menteri Sosial RI Agus Gumiwang
            Kartasasmita mengungkapkan, kemajuan teknologi informasi harus
            dimanfaatkan sebesar-besarnya untuk memperbaiki kehidupan masyarakat
            prasejahtera. Salah satunya melalui koperasi berbasis digital, yakni
            Koperasi Jaringan Masyarakat Indonesia Sejahtera (KJMIS) yang
            diluncurkan bersamaan dengan kegiatan Penguatan Kapasitas Pendamping
            Sosial Bantuan Sosial Pangan di Jakarta, Selasa.(12/3/19)
            <br />
            <br />
            “Pertemuan ini memiliki benang merah, yaitu pertama bagaimana kita
            terus berupaya memperbaiki kehidupan masyarakat prasejahtera. Kedua,
            memanfaatkan teknologi dan inovasi yang ada untuk mensejahterakan
            mereka,” tutur Mensos dalam sambutannya.
            <br />
            <br />
            Langkah awal berjalannya KJMIS adalah adanya Nota Kesepahaman antara
            Direktur Jenderal Penanganan Fakir Miskin dan Koperasi Jaringan
            Masyarakat Indonesia Sejahtera (KJMIS) tentang Pemberdayaan Kelompok
            Usaha Bersama dalam Rangka Penanganan Fakir Miskin.
            <br />
            <br />
            KJMIS merupakan badan usaha berbentuk koperasi yang berdiri 12
            Desember 2017 sesuai dengan keputusan Menteri Koperasi dan Usaha
            Kecil dan Menengah Nomor 006503 BH/ M.KUKM.2 /XII/2017. Tujuan KJMIS
            adalah koperasi Primer Nasional yang akan merangkul
            koperasi-koperasi yang ada di Indonesia.
            <br />
            <br />
            Menteri menjelaskan, KJMIS menempatkan anggotanya sebagai pemilik
            dan sebagai pelanggan. Anggota koperasi dapat berbelanja di KJMIS
            dan mendapatkan manfaat atau hak-hak sebagai anggota seperti
            potongan harga dan bonus, serta pembagian sisa hasil usaha.
            <br />
            <br />
            “Kemudian, misalnya KPM ingin mengembangkan diri dan ingin membuka
            usaha rumahan maka sebagai anggota koperasi mereka memiliki hak
            meminjam modal agar bisa memulai usahanya tersebut,” terang Mensos.
            <br />
            <br />
            Keanggotaan koperasi ini bersifat suka rela dan setiap warga negara
            Indonesia berhak angggotanya, diutamakan adalah masyarakat
            prasejahtera. Dalam pelaksanaannya, KJMIS bekerja sama dengan
            Himpunan Bank Milik Negara (Himbara), Pay Tren, Rajawali Nusantara
            Indonesia (RNI), dan Bulog.
            <br />
            <br />
            Untuk menjadi anggota, ada beberapa langkah yang dilakukan yakni
            mendaftar secara online dan memasukkan Nomor Induk Kependudukan.
            Kemudian mengunduh aplikasi KJMIS di playstore dan mengikuti proses
            pendaftaran selanjutnya.
            <br />
            <br />
            “Saat ini salah satu inovasi yang akan dilakukan adalah untuk
            transportasi penyaluran Bantuan Pangan Nontunai untuk penerima
            bantuan, di mana melalui transportasi online Golek, KPM tidak perlu
            pergi mengambil bantuan pangan di e-warong. KPM dapat memesan
            kebutuhan pokok untuk kemudian diantarkan ke rumah KPM,” tutur Agus.
            <br />
            <br />
          </Typography>
        </Box>
      ) : null}
      {string === 'semua' ? (
        <Box pt={10}>
          <Typography variant='h2' sx={{ py: 3 }}>
            Mensos Luncurkan Koperasi Digital untuk Perbaikan Kehidupan
            Masyarakat Prasejahtera
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={kopdig}
            alt='Mensos Luncurkan Koperasi Digital untuk Perbaikan Kehidupan Masyarakat Prasejahtera'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            KBRN, Jakarta : Menteri Sosial Agus Gumiwang Kartasasmita
            mengatakan, kemajuan teknologi informasi harus dimanfaatkan
            sebesar-besarnya untuk memperbaiki kehidupan masyarakat
            prasejahtera. Salah satunya melalui koperasi berbasis digital yakni
            Koperasi Jaringan Masyarakat Indonesia Sejahtera (KJMIS), yang
            diluncurkan bersamaan dengan kegiatan Penguatan Kapasitas Pendamping
            Sosial Bantuan Sosial Pangan di Jakarta, Selasa (12/3/2019). <br />
            <br />
            “Pertemuan ini memiliki benang merah yaitu pertama, bagaimana kita
            terus berupaya memperbaiki kehidupan masyarakat prasejahtera. Kedua,
            memanfaatkan teknologi dan inovasi yang ada untuk mensejahterakan
            mereka,” jelas Mensos dalam sambutannya.
            <br />
            <br />
            Langkah awal berjalannya KJMIS adalah adanya Nota Kesepahaman antara
            Direktur Jenderal Penanganan Fakir miskin dengan Koperasi Jaringan
            Masyarakat Indonesia Sejahtera (KJMIS) tentang Pemberdayaan Kelompok
            Usaha Bersama Dalam Rangka Penanganan Fakir Miskin.
            <br />
            <br />
            KJMIS merupakan badan usaha berbentuk koperasi yang berdiri pada
            tanggal 12 Desember 2017 sesuai dengan keputusan Menteri Koperasi
            dan Usaha Kecil dan Menengah Nomor 006503 BH/ M.KUKM.2 /XII/2017.
            <br />
            <br />
            Tujuan KJMIS adalah koperasi Primer Nasional yang akan merangkul
            koperasi-koperasi yang ada di Indonesia. Menteri menjelaskan KJMIS
            menempatkan anggotanya sebagai pemilik dan sebagai pelanggan.
            Anggota koperasi dapat berbelanja di KJMIS dan mendapatkan manfaat
            atau hak-hak sebagai anggota seperti potongan harga dan bonus, serta
            pembagian sisa hasil usaha.
            <br />
            <br />
            “Kemudian misalnya KPM ingin mengembangkan diri dan ingin membuka
            usaha rumahan, maka sebagai anggota koperasi mereka memiliki hak
            meminjam modal agar bisa memulai usahanya tersebut,” ungkap Mensos.
            <br />
            <br />
            Menurutnya, keanggotaan koperasi ini bersifat suka rela dan setiap
            warga negara Indonesia berhak angggotanya, diutamakan adalah
            masyarakat prasejahtera. Dalam pelaksanaannya, KJMIS bekerja sama
            dengan Himpunan Bank Milik Negara (Himbara), Pay Tren, Rajawali
            Nusantara Indonesia (RNI) dan Bulog. Untuk menjadi anggota, ada
            beberapa langkah yang dilakukan yakni mendaftar secara online dan
            memasukkan Nomor Induk Kependudukan.
            <br />
            <br />
            Kemudian mengunduh aplikasi KJMIS di playstore dan mengikuti proses
            pendaftaran selanjutnya. “Saat ini salah satu inovasi yang akan
            dilakukan adalah untuk transportasi penyaluran Bantuan Pangan Non
            Tunai untuk penerima bantuan, dimana melalui transportasi online
            Golek, KPM tidak perlu pergi mengambil bantuan pangan di e-warong.
            KPM dapat memesan kebutuhan pokok untuk kemudian diantarkan ke rumah
            KPM,” kata Agus.
          </Typography>
          <Link
            href='https://rri.co.id/nasional/peristiwa/647397/mensos-luncurkan-koperasi-digital-untuk-perbaikan-kehidupan-masyarakat-prasejahtera'
            underline='none'
          >
            <Typography>Sumber</Typography>
          </Link>
        </Box>
      ) : null}
      {string === 'n3' || string === 'semua' ? (
        <Box pt={10}>
          <Typography variant='h2' sx={{ py: 3 }}>
            E-Warong dan KJMIS Hadir di Nunukan
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={salaman}
            alt='E-Warong dan KJMIS Hadir di Nunukan'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            Direktorat Jenderal Penanganan Fakir Miskin Kementerian Sosial Andi
            ZA Dulung merilis program e-Warong untuk penyaluran beras sejahtera
            yang berganti nama menjadi Bantuan Pangan Non Tunai (BNPT) di
            Kabupaten Nunukan Senin, 7 Januari 2019.
          </Typography>
          <Link
            href='https://video.medcom.id/video-advertorial/eN4OwMoK-e-warong-dan-kjmis-hadir-di-nunukan'
            underline='none'
          >
            <Typography>Sumber</Typography>
          </Link>
        </Box>
      ) : null}
      {string === 'semua' ? (
        <Box pt={10}>
          <Typography variant='h2' sx={{ py: 3 }}>
            Mensos Agus Gumiwang Luncurkan Koperasi Digital
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={kartu}
            alt='Mensos Agus Gumiwang Luncurkan Koperasi Digital'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            Jakarta: Kementerian Sosial (Kemensos) masih merumuskan program
            Kartu Sembako Murah dengan berbagai kementerian. Kartu Sembako Murah
            pertama kali diungkapkan oleh Presiden Joko Widodo pada akhir
            Februari 2019.
            <br />
            <br />
            “Masih dirumuskan dengan berbagai kementerian, khususnya Kementerian
            Keuangan dan Kementerian PPN/Bappenas,” ujar Menteri Sosial Agus
            Gumiwang Kartasasmita, ditemui di kantor pusat Kemensos, Jakarta,
            Selasa, 12 Maret 2019.
            <br />
            <br />
            Agus mengatakan Kemensos menyiapkan tiga pilihan untuk menerapkan
            Kartu Sembako Murah. Salah satunya ialah mentransformasi bantuan
            pangan non-tunai (BPNT) menjadi Kartu Sembako Murah.
            <br />
            <br />
            “Ini baru opsi (pilihan) ya. Dari tiga opsi, salah satunya
            transformasi BPNT ke Kartu Sembako Murah,” ujarnya.
            <br />
            <br />
            Agus mengapresiasi program Kartu Sembako Murah yang diungkapkan
            Presiden Jokowi. Menurutnya, Kartu Sembako Murah merupakan bentuk
            kehadiran negara untuk masyarakat pra sejahtera dan diharapkan dapat
            mengurangi beban pengeluaran masyarakat pra sejahtera.
            <br />
            <br />
            Sebelumnya, Direktur Komunikasi Politik Tim Kampanye Nasional (TKN)
            Joko Widodo-Ma’ruf Amin, Usman Kansong, menyebut program Kartu
            Sembako Murah tak akan tumpang tindih dengan program bansos yang
            sudah ada.
            <br />
            <br />
            Kartu Sembako Murah justru meningkatkan program bansos yang ada,
            seperti BPNT. “Ini akan ditingkatkan lagi, nomor satu jumlah
            penerimanya, nomor dua peruntukkannya, lebih luas, dan bisa lebih
            spesifik,” kata Usman.
            <br />
            <br />
            Usman mengatakan, program Kartu Sembako Murah dan dua kartu sakti
            lain dihadirkan untuk meningkatkan bansos. Yang jelas, kata dia,
            semangat program tiga kartu sakti baru ini adalah untuk menyubsidi
            orang, bukan barang.
          </Typography>
          <Link
            href='https://www.medcom.id/nasional/politik/RkjR6zGk-kartu-sembako-murah-kurangi-beban-masyarakat-prasejahtera'
            underline='none'
          >
            <Typography>Sumber</Typography>
          </Link>
        </Box>
      ) : null}
      {string === 'semua' ? (
        <Box pt={10}>
          <Typography variant='h2' sx={{ py: 3 }}>
            Kemensos Luncurkan Aplikasi Koperasi Digital
          </Typography>
          <Divider sx={{ mb: 3, width: 100 }} />
          <CardMedia
            component='img'
            image={ramerame}
            alt='Kemensos Luncurkan Aplikasi Koperasi Digital'
          />
          <Typography sx={{ py: 3 }} variant='body1'>
            Jakarta: Kementerian Sosial (Kemensos) meluncurkan aplikasi koperasi
            digital bernama Koperasi Jaringan Masyarakat Indonesia Sejahtera
            (KJMIS).
            <br />
            <br />
            Peluncuran dipimpin oleh Menteri Sosial (Mensos) Agus Gumiwang
            Kartasasmita didampingi para pejabat Eselon I di kantor pusat
            Kemensos, Jakarta, Selasa, 12 Maret 2019. KJMIS dinilai dapat
            membantu pemerintah dalam mengentaskan kemiskinan. Selain itu,
            peluncuran KJMIS juga sejalan dengan program non tunai yang sedang
            dijalankan pemerintah.
            <br />
            <br />
            “KJMIS merupakan badan usaha bentuk koperasi sesuai keputusan
            Menteri Koperasi dan UKM. KJMIS merupakan koperasi primer nasional
            yang akan merangkul koperasi yang sudah ada,” ujar Agus.
            <br />
            <br />
            Agus menjelaskan, anggota KJMIS diposisikan sebagai pemilik dan
            pelanggan abadi. Anggota yang loyal diharapkan dapat memanfaatkan
            KJMIS sebagai tempat berbelanja. Anggota KJMIS yang berbelanja di
            e-Warong akan diberi potongan harga atau bonus.
            <br />
            <br />
            Belanja anggota akan menjadi perhitungan pembagian sisa hasil usaha
            yang akan diterima. Selanjutnya, KJMIS akan membagikan sisa hasil
            usaha selama setahun kepada anggotanya.
            <br />
            <br />
            “Ini membuktikan bahwa identitas KJMIS telah melakukan kegiatan
            bisnis dari anggotanya dan keuntungan dibalikkan kepada anggota. Hal
            ini sekaligus kami percaya penyaluran bisa lebih baik lagi,”
            ujarnya.
            <br />
            <br />
            Selain itu, para anggota juga bisa mengembangkan usahanya melalui
            KJMIS. “Sebagai anggota, dia punya hak meminjam modal dari koperasi
            agar bisa memulai jenis usaha yang ingin dia lakukan dan
            kembangkan,” kata Agus.
            <br />
            <br />
            Salah satu inovasi dalam koperasi ini adalah penyaluran
            transportasi. Penyaluran Bantuan pangan non tunai (BPNT) kepada
            Keluarga Penerima Manfaat (KPM) akan menggunakan online, sehingga
            KPM tidak perlu ke e-Warong lagi.
            <br />
            <br />
            Pada kesempatan yang sama, Direktur Jenderal Penanganan Fakir Miskin
            (PFM) Kemensos Andy ZA Dulung mengatakan anggota KJMIS terbuka untuk
            masyarakat umum, termasuk KPM. Para pendamping akan diarahkan untuk
            menggerakkan KPM menjadi anggota KJMIS.
            <br />
            <br />
            “Dalam anggaran dasar dan anggaran rumah tangga (AD/ART) yang
            menjadi anggota adalah KPM dalam Basis Data Terpadu (BDT). Artinya,
            40 persen orang bawah plus orang pemerhati dalam pemberdayaan itu.
            Ya, kita ini semua,” kata Andy.
            <br />
            <br />
            Anggota KJMIS sampai saat ini hampir mendekati seribu orang. Jakarta
            dipilih sebagai pilot project pada saat ini. Jika berhasil, KJMIS
            akan diberlakukan di berbagai daerah.
            <br />
            <br />
            “Ini kan belum sebulan pendaftaran. Soft launching di Nunukan,
            Januari 2019. Waktu itu baru satu fiturnya. Sekarang, fiturnya 80
            persen, makanya Pak Menteri minta luncurkan. Setelah 100 persen,
            kami akan grand launching,” kata Andy menjelaskan.
            <br />
            <br />
            Lebih lanjut, Andy menegaskan fungsi KJMIS sama seperti koperasi
            pada umumnya. “Pada dasarnya, seperti koperasi pada umumnya. Cuma
            karena kita nasional, untuk seluruh Indonesia, maka tidak bisa kalau
            tidak pakai aplikasi. Bagaimana menghitung jutaan orang kalau pakai
            buku manual. Jadi, harus pakai aplikasi,” tuturnya.
            <br />
            <br />
            Fokus pada Penguatan Pendamping
            <br />
            <br />
            Mensos Agus Gumiwang mengapresiasi kontribusi para pendamping bansos
            pangan, Korteks, dan Tenaga Kesejahteraan Sosial Kecamatan (TKSK)
            dalam menurunkan angka kemiskinan menjadi 9,6 persen. Mereka
            berperan agar penyaluran bansos tepat sasaran.
            <br />
            <br />
            Hal itu disampaikan Agus dalam acara Penguatan Kapasitas SDM-PFM
            usai peluncuran KJMIS. Tantangan menekan angka kemiskinan semakin
            besar, sehingga diperlukan penguatan kepada para pendamping agar
            memperoleh kinerja yang lebih baik lagi.
            <br />
            <br />
            “Teman-teman Korteks dan TKSK merupakan pilar dan kunci dari
            keberhasilan program pemerintah, khususnya BPNT. Kami percaya BPNT
            yang merupakan program unggulan kami berkontribusi mengurangi
            kemiskinan,” ucap Mensos Agus.
          </Typography>
          <Link
            href='https://www.medcom.id/nasional/peristiwa/5b2qRmMN-kemensos-luncurkan-aplikasi-koperasi-digital'
            underline='none'
          >
            <Typography>Sumber</Typography>
          </Link>
        </Box>
      ) : null}
      {/* </div>
      ))} */}
    </Container>
  )
}
