import React from 'react'

import bannerkjmis from 'src/new/assets/images/bannerkjmis.jpeg'

import apaitu from 'src/new/assets/images/undraw/apaitu.png'
import kegiatan from 'src/new/assets/images/undraw/kegiatan.png'
// import sekilas from 'src/new/assets/images/undraw/sekilas.png'
import tujuan from 'src/new/assets/images/undraw/tujuan.png'
import visimisi from 'src/new/assets/images/undraw/visimisi.png'

import andi from 'src/new/assets/images/anggota/andi.png'
import chrisny from 'src/new/assets/images/anggota/chrisny.jpeg'
// import disson from 'src/new/assets/images/anggota/disson.jpeg'
// import herlambang from 'src/new/assets/images/anggota/herlambang.jpeg'
import herman from 'src/new/assets/images/anggota/herman.jpeg'
// import imam from 'src/new/assets/images/anggota/imam.jpeg'

import indrajaya from 'src/new/assets/images/anggota/indrajaya.jpeg'

import nopicture from 'src/new/assets/images/anggota/nopicture.png'
// import sinta from 'src/new/assets/images/anggota/sinta.jpeg'
// import strukturorganisasi from 'src/new/assets/images/anggota/strukturorganisasi.jpeg'
// import tiffany from 'src/new/assets/images/anggota/tiffany.jpeg'
import veno from 'src/new/assets/images/anggota/veno.jpeg'
import yudhi from 'src/new/assets/images/anggota/yudhi.jpeg'
import ciptoutomo from 'src/new/assets/images/anggota/ciptoutomo.jpeg'

import {
  Container,
  Typography,
  CardMedia,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from '@mui/material'

import { ExpandMore, Mail } from '@mui/icons-material'

import { formatDateWithoutTime } from 'src/new/components/formatDate'

import { Link } from 'react-router-dom'

export function TentangKami() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const now = new Date()

  return (
    <Container sx={{ py: 10 }}>
      <CardMedia component='img' image={bannerkjmis} alt='bannerkjmis' />
      <Box
        sx={{
          width: 'auto',
          height: 40,
          backgroundColor: '#dd4949',
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography color='white'>{formatDateWithoutTime(now)}</Typography>
          <Link
            to='mailto:cs@kjmis.id'
            underline='hover'
            sx={{ paddingLeft: 1 }}
          >
            <Button variant='text' sx={{ color: 'white' }} startIcon={<Mail />}>
              cs@kjmis.id
            </Button>
          </Link>
        </Grid>
      </Box>
      <Box>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Sekilas Tentang KJMIS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardMedia pt={3} component='img' image={apaitu} alt='apa itu' />
            <Typography py={2} variant='h4'>
              LEGALITAS
            </Typography>
            <Typography py={1} variant='subtitle2'>
              Badan usaha berbentuk koperasi dengan Akta Pendirian Notaris Hj.
              Huriah Sadeli, SH. Nomor 12 Tanggal 27 November 2017. Keputusan
              Menteri Koperasi dan Usaha Kecil dan Menengah Nomor :
              006503/BH/M.KUKM.2/XII/2017 Tentang Pengesahan Akta Pendirian
              Koperasi Jaringan Masyarakat Indonesia Sejahtera (KJMIS)
              <br></br>
              <br></br>
              NIK 3173030050005
              <br></br>
              NPWP 3.840.686.6-023.000
            </Typography>
            <Typography py={2} variant='h4'>
              WUJUD
            </Typography>
            <Typography py={1} variant='subtitle2'>
              Koperasi Jaringan Masyarakat Indonesia Sejahtera (KJMIS) merupakan
              badan usaha koperasi primer nasional berbasis digital modern.
              dimana Self Service, dapat diakses sendiri platform dari hanphone
              android (untuk saat ini). KJMIS melakukan berbagai inovasi dan
              memanjakan hidup melalui platform digital terkini dan mendukung
              gerakan non tunai berupa uang elektronik dengan nama KijangMas.
              Anggota KJMIS dapat melakukan kegiatan transaksi jual-beli
              di-Ecommers dengan membuka toko online diKijangMart.
            </Typography>
            <Typography py={2} variant='h4'>
              SEMANGAT DASAR
            </Typography>
            <Typography py={1} variant='subtitle2'>
              &rarr; Pendirian KJMIS merupakan deklarasi dari pemerhati
              pemberdayaan sosial dari seluruh Indonesia. untuk membentuk
              Koperasi Primer Nasional serta membangun perekonomian kerakyatan
              yang mandiri dan berdaulat.
              <br />
              <br />
              &rarr; KJMIS dijalankan dengan tujuan untuk kepentingan bersama
              atas asas kekeluargaan yang berdsarkan pada prinsip gerakan
              ekonomi rakyat.
              <br />
              <br />
              &rarr; Pada UU Koperasi No 25 tahun 1992 pasal 4 dijelaskan bahwa
              koperasi berfungsi dan berperan untuk mengembangkan potensi dan
              kemampuan ekonomi anggotanya.
              <br />
              <br />
              &rarr; UU No 11 tahun 2008 tentang informasi dan transaksi
              elektronik
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel3bh-content'
            id='panel3bh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Tujuan Utama
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardMedia py={3} component='img' image={tujuan} alt='apa itu' />
            <Typography>
              Terwujudnya perkoperasian yang tangguh dan tumbuh mandiri secara
              sehat dan dapat memberikan tingkat pelayanan yang memuaskan,
              berperan mendukung kebijakan keuangan inklusi pemerintah melalui
              aplikasi sistem pengelolaan elektronik yang berbasis teknologi
              informasi dalam aspek transaksi keuangan, sehingga menjadi mitra
              bisnis terpercaya dari pemerintah, koperasi, perusahaan, dan
              pelaku di Industri.
              <br />
              <br />
              Membangun taraf ekonomi anggota yang tergabung dalam KJMIS melalui
              pemberdayaan ekonomi mikro yang legal dan solutif untuk
              peningkatan kesejahteraan seluruh anggota koperasi.
              <br />
              <br />
              Mewujudkan koperasi mandiri yang tangguh, sehat dan professional
              serta mampu menjadi mitra terpercaya pemerintah dan pelaku bisnis
              di sektor industri pangan dan keuangan.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel4bh-content'
            id='panel4bh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Visi, Misi, Value
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardMedia py={3} component='img' image={visimisi} alt='apa itu' />
            <Typography py={2} variant='h4'>
              VISI
            </Typography>
            <Typography py={1} variant='subtitle2'>
              1. KJMIS adalah Koperasi Digital terdepan di Indonesia sebagai
              wadah yang solutif bagi pelaku usaha untuk tumbuh dan berkembang
              melalui perekonomian wadah legal yang unggul dan kreatif.
              <br />
              2. Mendukung upaya pemerintah dalam keuangan digital dan
              memperluas inklusi keuangan berikut pemanfaatannya secara
              seluas-luasnya untuk peningkatan kesejahteraan masyarakat dan
              pelaku usaha
            </Typography>
            <Typography py={2} variant='h4'>
              MISI
            </Typography>
            <Typography py={1} variant='subtitle2'>
              1. Menyediakan infrastruktur digital yang andal serta tata Kelola
              organisasi yang professional
              <br />
              2. Memberikan kemudahan akses integrative sesuai dengan
              program-program yang disediakan.
              <br />
              3. Memberikan kesempatan seluas-luasnya kepada anggota untuk
              meningkatkan taraf kehidupan melalu ekonomi kreatif sehingga
              tumbuh menjadi pelaku usaha yang sukses dan mandiri
            </Typography>
            <Typography py={2} variant='h4'>
              Value
            </Typography>
            <Typography py={1} variant='subtitle2'>
              K = Kreatif
              <br />
              A = Andal
              <br />
              K = Kepercayaan
              <br />
              A = Amanah
              <br />P = Professional
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel5bh-content'
            id='panel5bh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Kegiatan Utama KJMIS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardMedia py={3} component='img' image={kegiatan} alt='apa itu' />
            <Typography py={2} variant='h4'>
              Transaksi Keuangan
            </Typography>
            <Typography py={1} variant='subtitle2'>
              1. Simpanan pokok
              <br />
              2. Simpanan wajib
              <br />
              3. Rekening simpanan sukarela.
              <br />
              4. Pembelian & Pembayaran PPOB
              <br />
              5. Berbelanja & Berjualan pada KijangMart
              <br />
              6. transfer menggunakan mobile banking KijangMas
              <br />
              <br />
              Semua anggota otomatis mendapatkan Virtual Akun (VA) dari BNI dan
              BRI, memberi kebebasan bertransaksi terima transfer dari bank
              manapun, dan sebaliknya dapat mentransfer dana kesemua bank.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel6bh-content'
            id='panel6bh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Struktur Organisasi
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <CardMedia
              py={3}
              component='img'
              image={strukturorganisasi}
              alt='apa itu'
            />

            <Box
              sx={{
                width: 'auto',
                height: 10,
                backgroundColor: 'gray',
                marginTop: 5,
              }}
            /> */}

            <Typography variant='h3' py={5} textAlign='center'>
              PENGURUS PUSAT KJMIS
            </Typography>

            <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
              }}
            >
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={andi}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Dr. Andi Patunruang
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        KETUA
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={yudhi}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Yudhi Harris Nurdian
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        SEKRETARIS
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={chrisny}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Chrisny Yanuarini
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        BENDAHARA
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
              }}
            >
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={nopicture}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Rita Berlis
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        PENGAWAS
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={herman}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Herman Susilo
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        PENGAWAS
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={veno}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Venoneta Monetera
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        PENGAWAS
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: 'auto',
                height: 10,
                backgroundColor: 'gray',
                marginTop: 5,
              }}
            />

            <Typography variant='h3' py={5} textAlign='center'>
              PENGELOLA KJMIS PUSAT
            </Typography>

            <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
              }}
            >
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={ciptoutomo}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Cipto Utomo
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        DIREKTUR UMUM
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={indrajaya}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Indrajaya
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        MANAGER IT & RISET
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={nopicture}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Suaibah Hutasuhut
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        Accounting
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid> */}
            </Grid>
            {/* <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
              }}
            >
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={tiffany}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Tiffany Olivia
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        MANAJER UMUM
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item pb={{ xs: 2 }}>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={herlambang}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Herlambang
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        MANAGER BISNIS
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid> */}
            {/* <Grid item>
                <Card elevation={0} sx={{ maxWidth: 250 }}>
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      sx={{ height: 250, width: 250, borderRadius: 50 }}
                      image={sinta}
                      alt='green iguana'
                    />
                    <CardContent>
                      <Typography
                        textAlign='center'
                        gutterBottom
                        variant='h5'
                        component='div'
                      >
                        Sinta Putri Bestari
                      </Typography>
                      <Typography
                        textAlign='center'
                        variant='body2'
                        color='text.secondary'
                      >
                        MANAGER KEUANGAN & AKUNTANSI
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid> */}
            {/* </Grid> */}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  )
}
