import React from 'react'

import {
  CardActionArea,
  CardActions,
  Card,
  CardMedia,
  CardContent,
  Container,
} from '@mui/material'

import { Link } from 'react-router-dom'

import kopdig from 'src/new/assets/images/berita/kopdig.jpeg'
import mensospidato from 'src/new/assets/images/berita/mensospidato.jpeg'
import salaman from 'src/new/assets/images/berita/salaman.jpeg'

import { Box, Grid, Typography } from '@mui/material'

export function NewsSection() {
  return (
    <Box
      pt={{ xs: 1, sm: 2, md: 5, lg: 10, xl: 10 }}
      pb={{ xs: 2, sm: 2, md: 5, lg: 10, xl: 10 }}
    >
      <Container maxWidth='xl'>
        <Grid
          container
          alignContent='center'
          justifyContent='space-between'
          alignItems='center'
          sx={{ px: 4, pb: 6 }}
        >
          <Typography variant='h3'>Berita</Typography>
          <Link to='berita/semua' underline='none'>
            <Typography variant='button'>Lihat Semua</Typography>
          </Link>
        </Grid>
        <Grid container justifyContent='space-evenly' alignItems='center'>
          {/* <Grid item lg={4} pb={{ xs: 2 }}> */}
          <Card elevation={3} sx={{ maxWidth: 450 }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height='440'
                image={kopdig}
                alt='green iguana'
              />
              <CardContent>
                <Typography gutterBottom variant='h6' component='div'>
                  Mensos Agus Gumiwang Luncurkan Koperasi
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Menteri Sosial RI Agus Gumiwang…
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link
                to='berita/n0'
                style={{ paddingBottom: 10, paddingLeft: 5 }}
                underline='none'
              >
                <Typography>Lihat Selengkapnya</Typography>
              </Link>
            </CardActions>
          </Card>
          {/* </Grid> */}
          {/* <Grid item lg={4} pb={{ xs: 2 }}> */}
          <Card elevation={3} sx={{ maxWidth: 450 }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height='440'
                image={mensospidato}
                alt='green iguana'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Mensos Luncurkan Koperasi Digital
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Menteri Sosial RI…
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link
                to='berita/n1'
                style={{ paddingBottom: 10, paddingLeft: 5 }}
                underline='none'
              >
                <Typography>Lihat Selengkapnya</Typography>
              </Link>
            </CardActions>
          </Card>
          {/* </Grid> */}
          {/* <Grid item lg={4}> */}
          <Card elevation={3} sx={{ maxWidth: 450 }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height='440'
                image={salaman}
                alt='green iguana'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  E-Warong dan KJMIS Hadir di Nunukan
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Direktorat Jenderal Penanganan Fakir Miskin…
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link
                to='berita/n3'
                style={{ paddingBottom: 10, paddingLeft: 5 }}
                underline='none'
              >
                <Typography>Lihat Selengkapnya</Typography>
              </Link>
            </CardActions>
          </Card>
          {/* </Grid> */}
        </Grid>
      </Container>
    </Box>
  )
}
