import React from 'react'

import { Container, Grid, Box, Button, Typography } from '@mui/material'
import { AddLocation, LocalPhone, Mail } from '@mui/icons-material'

export function FooterComp() {
  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor='text.secondary'
        color='white'
      >
        <Container maxWidth='lg'>
          <Grid
            container
            alignContent='center'
            justifyContent='center'
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box borderBottom={1} pb={1}>
                <Typography variant='h5'>Alamat Kami</Typography>
              </Box>
              <Box pt={1}>
                <Button
                  variant='text'
                  color='inherit'
                  startIcon={<AddLocation />}
                >
                  Jl. Salemba Tengah 2 No. 1 H RT 005/ RW 008 Kel. Paseban, Kec.
                  Senen, Jakarta Pusat 10440
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box borderBottom={1} pb={1}>
                <Typography variant='h5'>Kontak Kami</Typography>
              </Box>
              <Box>
                <Button
                  variant='text'
                  color='inherit'
                  startIcon={<LocalPhone />}
                >
                  +62 8787-5125-117
                </Button>
              </Box>
              <Box>
                <Button variant='text' color='inherit' startIcon={<Mail />}>
                  cs@kjmis.id
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign='center' pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 10 }}>
            Koperasi Jaringan Masyarakat Indonesia Sejahtera &reg;{' '}
            {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  )
}
