import React from 'react'

import { Box, Grid, Typography, Avatar, Container } from '@mui/material'

import { useTheme, useMediaQuery } from '@material-ui/core'

import { CarouselComp } from 'src/new/components'

import logo from 'src/new/assets/images/kjmislogo.png'

export function IntroSection() {
  const theme = useTheme()

  const smallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const largescreen = useMediaQuery(theme.breakpoints.only('xl'))

  return (
    <>
      {smallScreen ? (
        <Container sx={{ pt: 10 }}>
          <Typography align='center' variant='h3' sx={{ fontWeight: 'bold' }}>
            <span style={{ color: 'gray' }}>"</span>Kami Hadir
            <br />
            Untuk <span style={{ color: 'orange' }}>Indonesia</span>
            <br />
            <span style={{ color: 'orange' }}>Sejahtera</span>
            <span style={{ color: 'gray' }}>"</span>
          </Typography>
          <br />
          <Typography
            align='center'
            variant='body1'
            sx={{ fontWeight: 'bold' }}
          >
            <span style={{ color: 'orange', fontWeight: 'bold' }}>K</span>
            operasi{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>J</span>
            aringan{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>M</span>
            asyarakat{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>I</span>
            ndonesia{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>S</span>
            ejahtera
          </Typography>
          <br />
          <CarouselComp />
        </Container>
      ) : mediumScreen ? (
        <Container sx={{ pt: 10 }}>
          <Typography align='center' variant='h1'>
            <span style={{ color: 'gray' }}>"</span>Kami Hadir Untuk{' '}
            <span style={{ color: 'orange' }}>Indonesia </span>
            <span style={{ color: 'orange' }}>Sejahtera</span>
            <span style={{ color: 'gray' }}>"</span>
          </Typography>
          <br />
          <Typography align='center' variant='h4'>
            <span style={{ color: 'orange', fontWeight: 'bold' }}>K</span>
            operasi{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>J</span>
            aringan{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>M</span>
            asyarakat{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>I</span>
            ndonesia{' '}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>S</span>
            ejahtera
          </Typography>
          <br />
          <CarouselComp />
        </Container>
      ) : (
        largescreen && (
          <Box xs={12} sx={{ flexGrow: 1, mx: 38, mt: 12 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Avatar
                  alt='kjmis'
                  src={logo}
                  sx={{ width: 220, height: 220 }}
                />
                <Typography variant='h1'>
                  <span style={{ color: 'gray' }}>"</span>Kami Hadir
                  <br />
                  Untuk <span style={{ color: 'orange' }}>Indonesia</span>
                  <br />
                  <span style={{ color: 'orange' }}>Sejahtera</span>
                  <span style={{ color: 'gray' }}>"</span>
                </Typography>
                <br />
                <Typography variant='h5'>
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>K</span>
                  operasi{' '}
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>J</span>
                  aringan{' '}
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>M</span>
                  asyarakat{' '}
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>I</span>
                  ndonesia{' '}
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>S</span>
                  ejahtera
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CarouselComp />
              </Grid>
            </Grid>
          </Box>
        )
      )}
    </>
  )
}
