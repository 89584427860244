import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { Link, Fab } from '@mui/material'
import { Beranda, Berita, TentangKami, Produk, Galeri } from './new/pages'

import { Routes, Route } from 'react-router-dom'

import { AppBarComp, FooterComp } from 'src/new/components'

import { useTheme, useMediaQuery } from '@material-ui/core'

import { WhatsApp } from '@mui/icons-material'

import Kijangmas from 'src/new/assets/images/kijangmas.svg'
// import kijangmasApk from 'src/new/assets/app/kijangmas.apk'

export default function App() {
  const theme = useTheme()

  const mobileAndTablet = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <CssBaseline />
      <AppBarComp />
      <Routes>
        <Route path='/' element={<Beranda />} />
        <Route path='berita/:string' element={<Berita />} />
        <Route path='tentangkami' element={<TentangKami />} />
        <Route path='produk' element={<Produk />} />
        <Route path='galeri' element={<Galeri />} />
      </Routes>
      {mobileAndTablet && (
        <>
          <a
            href='https://play.google.com/store/apps/details?id=com.amasalam.koperasi&hl=en'
            download
          >
            <Fab
              sx={{ position: 'fixed', bottom: 78, left: 18 }}
              variant='extended'
            >
              <img
                src={Kijangmas}
                alt=''
                width='20'
                height='20'
                style={{ marginRight: 10 }}
              />
              {/* <WhatsApp sx={{ mr: 1, color: 'green' }} /> */}
              unduh Kijangmas
            </Fab>
          </a>
          <Link href='https://api.whatsapp.com/send?phone=6287875125117&amp;text=Assalamualaikum,  Selamat datang di Akun Official Whatsapp KJMIS. Jika ada pertanyaan, silahkan kirim Nomor Anggota KJMIS Anda terlebih dahulu.  Terimakasih 🙏🏻'>
            <Fab
              sx={{ position: 'fixed', bottom: 18, left: 18 }}
              variant='extended'
            >
              <WhatsApp sx={{ mr: 1, color: 'green' }} />
              WhastApp Kami
            </Fab>
          </Link>
        </>
      )}
      {desktop && (
        <>
          <a
            href='https://play.google.com/store/apps/details?id=com.amasalam.koperasi&hl=en'
            download
          >
            <Fab
              sx={{ position: 'fixed', bottom: 78, left: 18 }}
              variant='extended'
            >
              <img
                src={Kijangmas}
                alt=''
                width='20'
                height='20'
                style={{ marginRight: 10 }}
              />
              {/* <WhatsApp sx={{ mr: 1, color: 'green' }} /> */}
              unduh Kijangmas
            </Fab>
          </a>
          <Link href='https://web.whatsapp.com/send?phone=6287875125117&amp;text=Assalamualaikum,  Selamat datang di Akun Official Whatsapp KJMIS. Jika ada pertanyaan, silahkan kirim Nomor Anggota KJMIS Anda terlebih dahulu.  Terimakasih 🙏🏻'>
            <Fab
              sx={{ position: 'fixed', bottom: 18, left: 18 }}
              variant='extended'
            >
              <WhatsApp sx={{ mr: 1, color: 'green' }} />
              WhastApp Kami
            </Fab>
          </Link>
        </>
      )}
      <FooterComp />
    </>
  )
}
