import React from 'react'

import {
  ImageList,
  ImageListItem,
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material'

import { Link } from 'react-router-dom'

import jaksel from 'src/new/assets/images/photos/jaksel.jpeg'
import jawatimur from 'src/new/assets/images/photos/jawatimur.jpeg'
import kabbatang from 'src/new/assets/images/photos/kabBatang.jpeg'
import kediri from 'src/new/assets/images/photos/kediri.jpeg'
import malang from 'src/new/assets/images/photos/malang.jpeg'
import probolinggo from 'src/new/assets/images/photos/probolinggo.jpeg'
import a1 from 'src/new/assets/images/photos/a1.jpeg'
import a2 from 'src/new/assets/images/photos/a2.jpeg'
import a3 from 'src/new/assets/images/photos/a3.jpeg'
import a4 from 'src/new/assets/images/photos/a4.jpeg'
import a5 from 'src/new/assets/images/photos/a5.jpeg'
import a6 from 'src/new/assets/images/photos/a6.jpeg'

export function GaleriSection() {
  const itemData = [
    {
      img: jaksel,
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: jawatimur,
      title: 'Burger',
    },
    {
      img: kabbatang,
      title: 'Camera',
    },
    {
      img: kediri,
      title: 'Coffee',
      cols: 2,
    },
    {
      img: malang,
      title: 'Hats',
      cols: 2,
    },
    {
      img: probolinggo,
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: a1,
      title: 'Basketball',
    },
    {
      img: a2,
      title: 'Fern',
    },
    {
      img: a3,
      title: 'Mushrooms',
      rows: 2,
      cols: 2,
    },
    {
      img: a4,
      title: 'Tomato basil',
    },
    {
      img: a5,
      title: 'Sea star',
    },
    {
      img: a6,
      title: 'Bike',
      cols: 2,
    },
  ]

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    }
  }

  return (
    <Box
      pt={{ xs: 1, sm: 2, md: 5, lg: 10, xl: 10 }}
      pb={{ xs: 2, sm: 2, md: 5, lg: 10, xl: 10 }}
    >
      <Container maxWidth='xl'>
        <Grid
          container
          alignContent='center'
          justifyContent='space-between'
          alignItems='center'
          sx={{ px: 4, pb: 6 }}
        >
          <Typography variant='h3'>Galeri</Typography>
          <Link to='galeri' underline='none'>
            <Typography>Lihat Semua</Typography>
          </Link>
        </Grid>
        <Grid
          container
          alignContent='center'
          justifyContent='center'
          alignItems='center'
        >
          <ImageList
            sx={{ width: 'auto', height: 'auto' }}
            variant='quilted'
            cols={4}
            rowHeight={121}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  {...srcset(item.img, 121, item.rows, item.cols)}
                  alt={item.title}
                  loading='lazy'
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Container>
    </Box>
  )
}
