import React from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  SwipeableDrawer,
  List,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemButton,
  Box,
} from '@mui/material'

import { Link } from 'react-router-dom'

import {
  Home,
  Announcement,
  Info,
  Dashboard,
  BurstMode,
} from '@mui/icons-material'

import MenuIcon from '@mui/icons-material/Menu'

import { useTheme, useMediaQuery } from '@material-ui/core'

import logo from 'src/new/assets/images/kjmislogo.png'

export function AppBarComp() {
  const theme = useTheme()

  const mobileAndTablet = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  const [state, setState] = React.useState({
    left: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  const url = window.location.pathname

  const [selectedIndex, setSelectedIndex] = React.useState(url)

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <>
      {mobileAndTablet ? (
        <div>
          {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
              <AppBar position='fixed' color='default'>
                <Toolbar>
                  <Typography variant='h6'>
                    <Avatar
                      alt='kjmis'
                      src={logo}
                      sx={{ width: 30, height: 30 }}
                    />
                  </Typography>
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ flexGrow: 1, pl: 1, pt: 0.3, fontWeight: 'bold' }}
                  >
                    KJMIS
                  </Typography>
                  <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <MenuIcon>{anchor}</MenuIcon>
                  </IconButton>
                </Toolbar>
              </AppBar>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <Box
                  sx={{ width: 250 }}
                  role='presentation'
                  onClick={toggleDrawer(anchor, false)}
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  <List component='nav' aria-label='main mailbox folders'>
                    <Link to='/' underline='none'>
                      <ListItemButton
                        selected={selectedIndex === '/'}
                        onClick={(event) => handleListItemClick(event, '/')}
                      >
                        <ListItemIcon>
                          <Home />
                        </ListItemIcon>
                        <ListItemText primary='Beranda' />
                      </ListItemButton>
                    </Link>
                    <Link to='berita/semua' underline='none'>
                      <ListItemButton
                        selected={selectedIndex === 'berita/semua'}
                        onClick={(event) =>
                          handleListItemClick(event, 'berita/semua')
                        }
                      >
                        <ListItemIcon>
                          <Announcement />
                        </ListItemIcon>
                        <ListItemText primary='Berita' />
                      </ListItemButton>
                    </Link>
                    <Link to='tentangkami' underline='none'>
                      <ListItemButton
                        selected={selectedIndex === 'tentangkami'}
                        onClick={(event) =>
                          handleListItemClick(event, 'tentangkami')
                        }
                      >
                        <ListItemIcon>
                          <Info />
                        </ListItemIcon>
                        <ListItemText primary='Tentang Kami' />
                      </ListItemButton>
                    </Link>
                    <Link to='produk' underline='none'>
                      <ListItemButton
                        selected={selectedIndex === 'produk'}
                        onClick={(event) =>
                          handleListItemClick(event, 'produk')
                        }
                      >
                        <ListItemIcon>
                          <Dashboard />
                        </ListItemIcon>
                        <ListItemText primary='Produk' />
                      </ListItemButton>
                    </Link>
                    <Link to='galeri' underline='none'>
                      <ListItemButton
                        selected={selectedIndex === 'galeri'}
                        onClick={(event) =>
                          handleListItemClick(event, 'galeri')
                        }
                      >
                        <ListItemIcon>
                          <BurstMode />
                        </ListItemIcon>
                        <ListItemText primary='Galeri' />
                      </ListItemButton>
                    </Link>
                  </List>
                </Box>
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <AppBar position='sticky' color='default'>
          <Toolbar sx={{ mx: 15 }}>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              <Avatar alt='kjmis' src={logo} sx={{ width: 50, height: 50 }} />
            </Typography>
            <Link to='/' underline='none'>
              <Button color='inherit'>BERANDA</Button>
            </Link>
            <Link to='berita/semua' underline='none'>
              <Button color='inherit'>BERITA</Button>
            </Link>
            <Link to='tentangkami' underline='none'>
              <Button color='inherit'>TENTANG KAMI</Button>
            </Link>
            <Link to='produk' underline='none'>
              <Button color='inherit'>PRODUK</Button>
            </Link>
            <Link to='galeri' underline='none'>
              <Button color='inherit'>GALERI</Button>
            </Link>
          </Toolbar>
        </AppBar>
      )}
    </>
  )
}
