import React from 'react'

import '@fontsource/roboto/300.css'

import { Divider } from '@mui/material'

import {
  IntroSection,
  NewsSection,
  AppSection,
  GaleriSection,
} from './components'

export function Beranda() {
  return (
    <>
      <IntroSection />
      <Divider sx={{ pt: 12, mx: 28 }} />
      <AppSection />
      <Divider sx={{ pt: 2, mx: 68 }} />
      <NewsSection />
      <Divider sx={{ pt: 2, mx: 68 }} />
      <GaleriSection />
    </>
  )
}
