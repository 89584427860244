import React from 'react'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import kopdig from 'src/new/assets/images/berita/kopdig.jpeg'
import mensospidato from 'src/new/assets/images/berita/mensospidato.jpeg'
import salaman from 'src/new/assets/images/berita/salaman.jpeg'
import kartu from 'src/new/assets/images/berita/kartu.jpeg'
import ramerame from 'src/new/assets/images/berita/ramerame.jpeg'

export function CarouselComp() {
  return (
    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
      <div>
        <img alt='' src={kopdig} />
        <h2 className='legend'>
          Mensos Agus Gumiwang Luncurkan Koperasi Digital
        </h2>
      </div>
      <div>
        <img alt='' src={salaman} />
        <h2 className='legend'>E-Warong dan KJMIS Hadir di Nunukan</h2>
      </div>
      <div>
        <img alt='' src={mensospidato} />
        <h2 className='legend'>
          Mensos Luncurkan Koperasi Digital untuk Perbaikan Kehidupan Masyarakat
          Prasejahtera
        </h2>
      </div>
      <div>
        <img alt='' src={kartu} />
        <h2 className='legend'>
          Kartu Sembako Murah Kurangi Beban Masyarakat Prasejahtera
        </h2>
      </div>
      <div>
        <img alt='' src={ramerame} />
        <h2 className='legend'>Kemensos Luncurkan Aplikasi Koperasi Digital</h2>
      </div>
    </Carousel>
  )
}
