import React from 'react'

import {
  Button,
  CardActionArea,
  CardActions,
  Card,
  CardMedia,
  CardContent,
  Container,
  Box,
  Grid,
  Typography,
  Link,
} from '@mui/material'

import ReactPlayer from 'react-player'

import kijangmart from 'src/new/assets/images/bannerkmart.png'

// import kijangmas from 'src/new/assets/app/kijangmas.apk'

export function AppSection() {
  return (
    <Box
      pt={{ xs: 1, sm: 2, md: 5, lg: 10, xl: 10 }}
      pb={{ xs: 2, sm: 2, md: 5, lg: 10, xl: 10 }}
    >
      <Container maxWidth='xl'>
        <Grid sx={{ px: 4, pb: 6 }}>
          <Typography variant='h3'>Aplikasi (android only)</Typography>
        </Grid>
        <Grid container justifyContent='space-evenly' alignItems='flex-start'>
          {/* <Grid item lg={4} pb={{ xs: 2 }}> */}
          <Card elevation={3} sx={{ maxWidth: 350, maxHeight: 550 }}>
            <CardActionArea>
              <ReactPlayer url='https://youtu.be/c8xRd0p4eAE' width={350} />
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  KijangMas
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Video Tutorial Menjadi Anggota Kijangmas
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size='small' color='primary'>
                <Link
                  underline='none'
                  href='https://play.google.com/store/apps/details?id=com.amasalam.koperasi&hl=en'
                  color='inherit'
                >
                  Unduh Aplikasi
                </Link>
                {/* <a href={kijangmas} download>
                  Unduh Aplikasi
                </a> */}
              </Button>
            </CardActions>
          </Card>
          {/* </Grid> */}
          {/* <Grid item lg={8}> */}
          <Card elevation={3} sx={{ maxWidth: 350, maxHeight: 550, mt: 5 }}>
            <CardActionArea>
              <CardMedia component='img' image={kijangmart} alt='kijangmart' />
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  KijangMart
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Aplikasi Virtual Warung Anggota KijangMas
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size='small' color='primary'>
                <Link
                  underline='none'
                  href='https://play.google.com/store/apps/details?id=com.kjmis.kijangmart&hl=en'
                  color='inherit'
                >
                  Unduh Aplikasi
                </Link>
              </Button>
            </CardActions>
          </Card>
          {/* </Grid> */}
        </Grid>
      </Container>
    </Box>
  )
}
